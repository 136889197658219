import React from 'react';
import { Segment, Dropdown } from 'semantic-ui-react'

const Header = props => {
    const options = [
        { key: 1, text: 'Rob', value: 'rob' }
    ]

    const handleChange = (e, item) => {
        props.onSelect(item.value);
    };

    return (
        <div>
            <Segment>
                <div className='header'>
                    <h3 className='title'>A-Z Cleaning Email Signatures</h3>

                    <Dropdown
                        onChange={handleChange}
                        options={options}
                        placeholder='Choose a Signature'
                        selection
                    />
                </div>
            </Segment>
        </div>
    );
};

export default Header;