import { React, useEffect, useRef } from 'react';
import { Divider, Button } from 'semantic-ui-react';

function SigContent(props) {
    const sigFrame = useRef();
    const userAlert = useRef();

    useEffect(() => {
        // store DOM elements
        sigFrame.current = document.getElementById("sig");
        userAlert.current = document.getElementById("userAlert");
    });

    const CopySignature = () => {
        // select iframe content and copy
        let iframeDoc = sigFrame.current.contentDocument || sigFrame.current.contentWindow.document;
        iframeDoc.execCommand('selectall');
        iframeDoc.execCommand("copy");
        iframeDoc.execCommand('unselect');

        // display user alert & hide after set time
        userAlert.current.classList.add('fadeIn');
        setTimeout(() => {
            userAlert.current.classList.remove('fadeIn');
        }, 2000);
    }

    return (
        <div>
            <iframe id="sig" src={props.url} width="100%" height="400px" frameBorder="none" title="signature" />
            <Divider />

            <div className='copySection'>
                <Button primary onClick={CopySignature}>Copy Signature</Button>
                <p className="user-alert" id="userAlert">signature copied</p>
            </div>
        </div>
    );
}

export default SigContent;