import React, { useState } from 'react';
import Header from './Header';
import SigContent from "./SigContent";

// styles
import 'semantic-ui-css/semantic.min.css';
import '../styles/styles.css';

const config = [
    { id: 'rob', url: './sigs/rob.html' }
];

const App = () => {
    const [currSignature, setSignature] = useState(null);

    const loadSignature = (value) => {
        let sigObj = config.find(x => x.id === value);
        setSignature(sigObj.url);
    };

    return (
      <div className="container">
          <Header onSelect={loadSignature} />
          {currSignature && <SigContent url={currSignature} />}
      </div>
    );
}

export default App;